<template>
  <ul>
    <li>
      <a href="https://www.youtube.com/@UndeadPlates" target="_blank" rel="opener" title="Undead Plates YouTube channel">
        <font-awesome-icon icon="fa-brands fa-youtube"/>&nbsp;Youtube
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/UndeadPlates/" target="_blank" rel="opener" title="Undead Plates on Instagram">
        <font-awesome-icon icon="fa-brands fa-instagram"/>&nbsp;Instagram
      </a>
    </li>
    <li>
      <a href="https://www.tiktok.com/@undeadplates" target="_blank" rel="opener" title="Undead Plates on TikTok">
        <font-awesome-icon icon="fa-brands fa-tiktok"/>&nbsp;TikTok
      </a>
    </li>
<!--    <li>-->
<!--      <a href="https://twitter.com/UndeadPlates" target="_blank" rel="opener"  title="Undead Plates on Twitter">-->
<!--        <font-awesome-icon icon="fa-brands fa-twitter"/>&nbsp;Twitter-->
<!--      </a>-->
<!--    </li>-->
  </ul>
</template>

<script>
export default {
  name: 'AppLinks',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  display: flex;
}

ul {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  list-style-type: none;
  padding: 0;
}

a {
  display: block;
  color: black;
  text-decoration: none;
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.5rem;

  transition: background-color 500ms ease;
}

a:hover {
  background-color: yellow;
}


</style>
