<template>
  <img alt="Undead Plates skull burger logo" src="./assets/logo.png" id="logo">
  <AppLinks />
  <h1>Bon appétit and welcome to the realm of Undead Plates</h1>
  <p>
    <strong>Cooking channel for everyone! Combining my hobbies and create fun stuff.</strong><br /><br />
    🍽️🕹️🍕💻🎶🤘🏻<br /><br />
    Join me on fresh fun delicious YouTube cooking channel. Undead Plates revolves around cooking, experiments and unconventional recipes for everyone! So crank up the heat, sharpen those knives and join the adventure.<br /><br />

    <em>A "dead plate" refers to food that can’t be served. Possible reasons include being cold, overcooked, or forgotten by a server.</em>
  </p>

  <h2>Mystery Bowl Challenge</h2>
  <p>
    <strong>As a MasterChef TV show fan, i love the Mystery Box Challenge. In this challenge the contestants must cook a dish that meets certain requirements using specific ingredients, equipment or inspiring a certain taste.</strong><br /><br />

    So here is the Undead Plates take on that; the Mystery Bowl Challenge. The bowl contains almost 100 balls with cooking related subjects which can be combined with each other. Each challenge 3 balls with different categories (colors) will be drawn. With these three requirements a dish will be created. Making things fun and interesting, sharing successes and failures along the way.
  </p>
  <h3>Pink category</h3>
  <p>{{ countries.join(", ") }}</p>

  <h3>Blue category</h3>
  <p>{{ colors.join(", ") }}</p>

  <h3>Green category</h3>
  <p>{{ misc.join(", ") }}</p>

  <h3>Yellow category</h3>
  <p>{{ ingredients.join(", ") }}</p>

  <footer>&copy; Switching Brains</footer>
</template>

<script setup>
import AppLinks from './components/AppLinks.vue'

const countries = [
  "Netherlands",
  "Denmark",
  "Germany",
  "Italy",
  "USA",
  "Japan",
  "Korea",
  "Mexico",
  "Australia",
  "China",
  "Canada",
];

countries.sort();

const colors = [
    "White",
    "Black",
    "Yellow",
    "Orange",
    "Red",
    "Purple",
    "Blue",
    "Green",
    "Pink",
    "Brown",
];

colors.sort();

const misc = [
    "Ugly Delicious",
    "Fast Food",
    "Cooking",
    "Baking",
    "Fusion",
    "Smash",
    "Summer",
    "Spring",
    "Autumn",
    "Winter",
];

misc.sort();

const ingredients = [
    "Biscuits",
    "Bread",
    "Chocolate",
    "Cheese",
    "Fruit",
    "Grains",
    "Potatoes",
    "Ice Cream",
    "Meat",
    "Seafood",
    "Eggs",
    "Dairy",
    "Pasta",
    "Pizza",
    "Rolls",
    "Soup",
    "Sushi",
    "Candy",
    "Alcohol",
    "Soda",
];

ingredients.sort();


</script>

<style lang="scss">
$background-color: #ff3200;
$color: white;

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');

@font-face {
  font-family: "Grumaz";
  src: url("../public/fonts/Grumaz-Regular.otf");
  font-style: normal;
  font-weight: normal;
}

html, body {
  background-color: $background-color;
  height: 100%;

  font-size: 16px;
}

body {
  margin: 2rem 2rem;
}

#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

#logo {
  width: 100%;
  max-width: 480px;
}

//h1 {
//  font-family: Grumaz, sans-serif;
//  font-size: 4rem;
//  margin: 0.5rem 0 1rem 0;
//  line-height: 3.5rem;
//  font-weight: normal;
//}

h3 {
  margin: 0;
}

footer {
  font-size: 0.75rem;
  color: rgba(255,255,255,0.25);
  margin-bottom: 2rem;
}
</style>
